import { FirebaseAuthManager } from '../firebase/firebase'

export interface IAuthManager {
    getAuthToken: () => Promise<string | undefined>
}

export default class AuthProvider {
    static getAuthManager(): IAuthManager {
        return new FirebaseAuthManager()
    }
}
