import {
    DeleteTwoTone,
    InfoCircleOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons'
import {
    Alert,
    Button,
    Card,
    Col,
    Descriptions,
    Empty,
    Modal,
    Popconfirm,
    Popover,
    Row,
} from 'antd'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import {
    CapRoverInstance,
    IProConfig,
    ProAlertEvent,
    SubscriptionStatus,
} from '../../models/ApiModels'
import Toaster from '../../utils/Toaster'
import Utils from '../../utils/Utils'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'
import ErrorRetry from '../global/ErrorRetry'

const BOX_SHADOW = '1px 1px 20px #e6e6e6'

export default class Dashboard extends ApiComponent<
    RouteComponentProps<any>,
    {
        isLoading: boolean
        apiData:
            | {
                  instances: CapRoverInstance[]
                  subscriptionStatus: SubscriptionStatus | undefined
              }
            | undefined
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            apiData: undefined,
        }
    }

    componentDidMount() {
        this.fetchInfo()
    }

    fetchInfo() {
        const self = this

        return Promise.resolve()
            .then(function () {
                self.setState({ isLoading: true })
                return Promise.all([
                    self.apiManager.getAllInstances(),
                    self.apiManager.getSubscriptionStatus(),
                ])
            })
            .then(function (dataRaw) {
                const [instancesInfo, subscriptionStatus] = dataRaw

                let updatedApiData = Utils.copyObject(self.state.apiData)
                if (!updatedApiData) {
                    updatedApiData = {
                        instances: [],
                        subscriptionStatus: undefined,
                    }
                }

                // data.instances = [
                //     {
                //         instanceUuid: '48c41f22-3ac3-4e50-89b6-5315570577fe',
                //         apiKey: 'api-key:uiouvth290809384j59830f85j930f8kgdsjlkjg',
                //         url: 'https://captain.server.demo.caprover.com',
                //         otpSecret: '0000123213456465465465465465',
                //         userId: '1234564564',
                //         proFeatures: {
                //             otpEnabled: true,
                //             loginAlertEnabled: true,
                //             healthCheckAlertEnabled: false,
                //             keepProAuditHistory: false,
                //             buildFailedAlertEnabled: false,
                //             buildSucceedAlertEnabled: true,
                //         },
                //     },
                //     {
                //         instanceUuid: '89b6-3ac3-4e50-89b6-48c41f22',
                //         apiKey: 'api-key:uiouvth290809384j59830f85j930f8kgdsjlkjg',
                //         url: '',
                //         otpSecret: '1232134564654654000065465465',
                //         userId: '1234564564',
                //         proFeatures: undefined,
                //     },
                //     {
                //         instanceUuid: '48c41f22-3ac3-4e50-89b6-5315570577fe',
                //         apiKey: 'api-key:uiouvth290809384j59830f85j930f8kgdsjlkjg',
                //         url: 'https://captain.server.demo.caprover.com',
                //         otpSecret: '0000123213456465465465465465',
                //         userId: '1234564564',
                //         proFeatures: undefined,
                //     },
                // ]

                updatedApiData.instances = instancesInfo.instances
                updatedApiData.subscriptionStatus = subscriptionStatus
                self.setState({ apiData: updatedApiData })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    render() {
        const self = this

        if (self.state.isLoading) {
            return (
                <div>
                    <CenteredSpinner />
                </div>
            )
        }

        if (!self.state.apiData) {
            return <ErrorRetry />
        }

        return (
            <div>
                <div style={{ height: 50 }} />
                <Row justify="center" gutter={20} align={'middle'}>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => {
                            self.createNewInstance()
                        }}
                    >
                        <PlusCircleOutlined /> Link a new Server
                    </Button>
                    <Popover
                        content={
                            <div>
                                To upgrade a CapRover instance to Pro:
                                <ul>
                                    <li>Click on "Link a new Server" button</li>
                                    <li>Copy the API key for the new server</li>
                                    <li>Login to your CapRover instance</li>
                                    <li>
                                        Navigate to Settings, and link the API
                                        key
                                    </li>
                                </ul>
                            </div>
                        }
                    >
                        <QuestionCircleOutlined
                            style={{
                                fontSize: '16px',
                                color: '#777',
                                paddingLeft: 10,
                            }}
                        />
                    </Popover>
                </Row>
                <div style={{ height: 10 }} />
                <Row justify="center" gutter={20}>
                    <Col
                        style={{ margin: 20 }}
                        lg={{ span: 18 }}
                        xs={{ span: 23 }}
                    >
                        <Card style={{ height: '100%' }} title="Instances">
                            {self.state.apiData.instances.length === 0
                                ? self.createNoInstances()
                                : self.state.apiData.instances.map((it) =>
                                      self.createInstance(it)
                                  )}
                        </Card>
                    </Col>
                </Row>

                {self.createInfoNoteForPaidUsers()}
            </div>
        )
    }
    createInfoNoteForPaidUsers() {
        const self = this
        if (!self.state.apiData?.subscriptionStatus?.isActive) return null

        return (
            <div>
                <div style={{ height: 50 }} />
                <Row justify="center" gutter={20}>
                    <Col
                        style={{ margin: 20 }}
                        lg={{ span: 18 }}
                        xs={{ span: 23 }}
                    >
                        <Alert
                            message="Important Note"
                            description={
                                <p>
                                    Make sure to bookmark the{' '}
                                    <a
                                        href="https://caprover.com/docs/troubleshooting-pro.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Pro Troubleshooting page
                                    </a>
                                    , just in case our Pro servers are down, so
                                    that you won't be locked out of your
                                    instances.
                                    <p>
                                        You can always email us at &nbsp;
                                        <span
                                            style={{ fontFamily: 'monospace' }}
                                        >
                                            pro.support@caprover.com
                                        </span>{' '}
                                        for support (24hr SLA)
                                    </p>
                                </p>
                            }
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    createNoInstances(): React.ReactNode {
        return (
            <Empty
                description={<span>Go ahead and add a new instance!</span>}
            ></Empty>
        )
    }
    displayUpgradeModal() {
        const self = this
        Modal.success({
            title: 'Upgrade Needed!',
            okText: 'Proceed',
            content: (
                <div>
                    <hr />
                    <br />
                    <p>
                        In order to add instances to your pro account, you will
                        need to upgrade your account to CapRover Pro.
                    </p>
                    <p>
                        Features such as two factor authentication, build
                        events, etc, are all available out of the box as soon as
                        you upgrade to Pro!
                    </p>
                    <p></p>
                </div>
            ),
            onCancel: () => {
                // nothing
            },
            onOk: () => {
                self.props.history.push('/subscription')
            },
        })
    }
    createNewInstance() {
        const self = this
        self.setState({ isLoading: true })

        if (!self.state.apiData?.subscriptionStatus?.isActive) {
            return self.displayUpgradeModal()
        }

        return this.apiManager
            .createNewInstance() //
            .then(function () {
                return self.fetchInfo()
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    deleteInstance(instanceUuid: string) {
        const self = this
        self.setState({ isLoading: true })

        return this.apiManager
            .deleteInstance(instanceUuid) //
            .then(function () {
                return self.fetchInfo()
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    createInstance(instance: CapRoverInstance) {
        const self = this

        return (
            <div key={instance.instanceUuid}>
                <Card
                    style={{
                        marginBottom: 30,
                        boxShadow: BOX_SHADOW,
                    }}
                    title=""
                >
                    <Descriptions
                        column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
                        bordered
                        title={
                            <div>
                                <span> Instance ID:</span>{' '}
                                <span>
                                    {' '}
                                    <span
                                        style={{
                                            fontFamily: 'monospace',
                                        }}
                                    >
                                        {instance.instanceUuid}
                                    </span>
                                </span>{' '}
                            </div>
                        }
                        size="small"
                        extra={
                            <Popconfirm
                                title={
                                    'Are you sure to delete this instance from Pro dashboard? ' +
                                    (instance.proConfigs
                                        ? ' \nThe connected instance will be downgraded to standard instance immediately.'
                                        : '')
                                }
                                onConfirm={() => {
                                    self.deleteInstance(instance.instanceUuid)
                                }}
                                onCancel={() => {
                                    // ignore
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="text">
                                    <DeleteTwoTone twoToneColor="#ed6d6d" />
                                </Button>
                            </Popconfirm>
                        }
                    >
                        <Descriptions.Item label="URL">
                            {instance.url || 'not connected yet'}
                        </Descriptions.Item>
                        <Descriptions.Item label="API Key">
                            {instance.apiKey}
                        </Descriptions.Item>
                    </Descriptions>

                    {self.createProFeatures(
                        instance.proConfigs,
                        !!instance.url?.trim()
                    )}
                </Card>
            </div>
        )
    }

    createProFeatures(
        proFeatures: IProConfig | undefined,
        isUrlConnected: boolean
    ) {
        if (!proFeatures && !isUrlConnected) {
            return (
                <div style={{ marginTop: 30 }}>
                    <InfoCircleOutlined style={{ marginRight: 15 }} />
                    It appears that this instance has not been connected to your
                    CapRover server yet. Navigate to your CapRover instance
                    dashboard. Enter the API Key for Pro Features in settings to
                    connect your instance.
                </div>
            )
        }

        function getText(b: boolean) {
            return <code>{b ? 'Enabled' : 'Disabled'}</code>
        }

        const alertsMapping = [
            {
                event: ProAlertEvent.UserLoggedIn,
                text: 'Login Alert',
            },
            {
                event: ProAlertEvent.AppBuildSuccessful,
                text: 'Build Succeeded',
            },
            {
                event: ProAlertEvent.AppBuildFailed,
                text: 'Build Failed',
            },
        ]

        function getAlerts() {
            return alertsMapping.map((alert) => {
                return (
                    <Descriptions.Item label={alert.text}>
                        {getText(
                            !!proFeatures &&
                                !!proFeatures?.alerts?.find(
                                    (it) => it.event === alert.event
                                )
                        )}
                    </Descriptions.Item>
                )
            })
        }

        return (
            <>
                <Descriptions
                    style={{
                        marginTop: 30,
                    }}
                    column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }}
                    bordered
                    title={<div> Alerts </div>}
                    size="small"
                >
                    {getAlerts()}
                </Descriptions>

                <div style={{ marginTop: 30 }}>
                    <InfoCircleOutlined />
                    &nbsp;&nbsp; You can change these configurations via the
                    Settings page on your instance.
                </div>
            </>
        )
    }
}
