import { InstancesInfo, SubscriptionStatus } from '../models/ApiModels'
import Logger from '../utils/Logger'
import AuthProvider from './AuthProvider'
import HttpClient from './HttpClient'

const BASE_DOMAIN = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL.replace(/\/$/, '')
    : ''
const URL = BASE_DOMAIN + '/api/v1'
Logger.dev(`API URL: ${URL}`)

export default class ApiManager {
    private http: HttpClient

    constructor() {
        this.http = new HttpClient(URL, AuthProvider.getAuthManager())
    }

    destroy() {
        this.http.destroy()
    }

    getAllInstances(): Promise<InstancesInfo> {
        const http = this.http

        return Promise.resolve() //
            .then(http.fetch(http.GET, '/user/instances', {}))
    }

    createNewInstance() {
        const http = this.http

        return Promise.resolve() //
            .then(http.fetch(http.POST, '/user/instances/new', {}))
    }

    deleteInstance(instanceUuid: string) {
        const http = this.http

        return Promise.resolve() //
            .then(
                http.fetch(http.POST, '/user/instances/delete', {
                    instanceUuid,
                })
            )
    }

    getSubscriptionStatus(): Promise<SubscriptionStatus> {
        const http = this.http

        return Promise.resolve() //
            .then(http.fetch(http.GET, '/user/subscription/current', {}))
    }

    callRandomEndpoint(someValue: string) {
        const http = this.http

        return Promise.resolve() //
            .then(
                http.fetch(http.POST, '/user/something', {
                    key: someValue,
                })
            )
    }
}
