// Import the functions you need from the SDKs you need
// import {
//     getAnalytics
// } from "firebase/analytics";
// const analytics = getAnalytics(app);
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

// firebase-config-staging.json
const firebaseConfig = require(`./${process.env.REACT_APP_FIREBASE_FILE}`)

const app = firebase.initializeApp(firebaseConfig)

export const auth = app.auth()

export class FirebaseAuthManager {
    getAuthToken() {
        return Promise.resolve() //
            .then(function () {
                return auth.currentUser?.getIdToken()
            })
    }

    getUser() {
        return Promise.resolve() //
            .then(function () {
                const user = auth.currentUser
                if (!user) {
                    throw new Error('user is null')
                }
                return user
            })
    }
}

export default app
