import { CheckCircleTwoTone } from '@ant-design/icons'
import { Card, Col, Layout, Row } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import FirebaseLoginButtons from '../../firebase/FirebaseLoginButtons'
import ApiComponent from '../global/ApiComponent'
import DarkModeSwitch from '../global/DarkModeSwitch'

interface LandingPageProps {
    emitSizeChanged: () => void
    isMobile: boolean
}

class LandingPage extends ApiComponent<
    LandingPageProps,
    {
        versionInfo: undefined
        collapsed: boolean
    }
> {
    render() {
        return (
            <div>
                <Layout
                    style={{
                        minHeight: '100vh',
                    }}
                >
                    <Header className="header">
                        <div>
                            <Row>
                                <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                    <div>
                                        <h4 style={{ color: '#fff' }}>
                                            <img
                                                alt="logo"
                                                src="/icon-512x512.png"
                                                style={{
                                                    height: 35,
                                                    marginRight: 5,
                                                }}
                                            />
                                            CapRover<b> Pro</b>
                                        </h4>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Row justify="end">
                                        <span
                                            style={{
                                                marginRight: 0,
                                            }}
                                        >
                                            <DarkModeSwitch />
                                        </span>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Header>
                    <Content
                        style={{
                            background: 'url("landing-page-bg.png")',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div>
                            <Row style={{ height: 'calc(100vh - 70px)' }}>
                                <Col
                                    lg={{ span: 12 }}
                                    xs={{ span: 24 }}
                                    id="landing-page-login-bg"
                                >
                                    <Row justify={'center'}>
                                        <div
                                            style={{
                                                paddingTop: 50,
                                            }}
                                        >
                                            <div
                                                id="landing-login-card"
                                                style={
                                                    {
                                                        // width: 380,
                                                        // margin: 20,
                                                        // border: '2px solid #888888',
                                                        // borderRadius: 12,
                                                        // boxShadow:
                                                        //     '5px 8px 29px 5px rgba(81, 31, 105, 0.6)',
                                                        // backgroundColor: 'white',
                                                        // padding: 30,
                                                    }
                                                }
                                            >
                                                <h3>
                                                    Log in to your account or
                                                    Sign up
                                                </h3>
                                                <div>
                                                    <FirebaseLoginButtons />

                                                    <div
                                                        style={{
                                                            fontSize: 11,
                                                            opacity: 0.5,
                                                        }}
                                                    >
                                                        <hr
                                                            style={{
                                                                margin: 30,
                                                            }}
                                                        />
                                                        * By logging in, you
                                                        accept{' '}
                                                        <a
                                                            href="/terms-of-service.html"
                                                            target="_blank"
                                                        >
                                                            Terms of Service.
                                                        </a>
                                                        <br />* Currently only
                                                        Google logins are
                                                        supported due to the
                                                        enhanced built-in
                                                        security and
                                                        verification in place.
                                                        In future other log-in
                                                        methods will be added.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col
                                    lg={{ span: 12 }}
                                    xs={{ span: 24 }}
                                    style={{
                                        background:
                                            'url("landing-page-bg.png")',
                                        padding: 60,
                                        backgroundSize: 'cover',
                                        opacity: 0.85,
                                    }}
                                >
                                    <Row justify={'center'}>
                                        <div>
                                            <Card
                                                className="pricing-card-landing"
                                                style={{
                                                    margin: 5,
                                                }}
                                                title={
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <img
                                                            alt="logo"
                                                            src="/logo-padded-pro.png"
                                                            style={{
                                                                height: 256,
                                                            }}
                                                        />

                                                        <h2
                                                            style={{
                                                                color: `#104f78`,
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            <b>
                                                                CapRover Pro
                                                                Plan
                                                            </b>
                                                        </h2>
                                                    </div>
                                                }
                                            >
                                                <p>
                                                    Get access to the premium
                                                    features built on top of one
                                                    of the most reliable open
                                                    source platforms!{' '}
                                                </p>
                                                <div style={{ margin: 20 }}>
                                                    <div>
                                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;Two-factor
                                                        authentication:
                                                    </div>
                                                    <div>
                                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;Proactive
                                                        login alerts
                                                    </div>
                                                    <div>
                                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;Build
                                                        failure and success
                                                        monitoring
                                                    </div>
                                                    <div>
                                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;More
                                                        to come!
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        margin: 30,
                                                        textAlign: 'center',
                                                    }}
                                                ></div>
                                            </Card>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </div>
        )
    }
}

export default LandingPage
