import React, { useContext, useEffect, useState } from 'react'
import { auth } from './firebase'

const AuthContext = React.createContext({})

export function useAuth(): any {
    return useContext(AuthContext)
}

export function AuthProvider(valueInput: any) {
    const children = valueInput.children
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [isAuthInited, setAuthInited] = useState(false)

    function signup(email: string, password: string) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email: string, password: string) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email: string) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email: string) {
        return (currentUser as any).updateEmail(email)
    }

    function updatePassword(password: string) {
        return (currentUser as any).updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            setCurrentUser(user)
            setLoading(false)
            setAuthInited(true)
        })

        return unsubscribe
    })

    const value = {
        isAuthInited,
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )

    // console.log(`${auth}` + `${useEffect} ${useState}`)
    // return <div>{children}</div>
}
