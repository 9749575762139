import firebase from 'firebase/compat/app'
import { StyledFirebaseAuth } from 'react-firebaseui'
import firebaseApp from './firebase'
// import './firebaseui-styling-extra.css' // Import globally.
import './firebaseui-styling.global.css' // Import globally.

export default function FirebaseLoginButtons() {
    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.GithubAuthProvider.PROVIDER_ID,
            // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false,
        },
    }

    return (
        <div>
            <StyledFirebaseAuth
                className={`styles-firebaseUi`}
                uiConfig={uiConfig}
                firebaseAuth={firebaseApp.auth()}
            />
        </div>
    )
}
