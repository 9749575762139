/**
 * ************************************* IMPORTANT **************************************
 * This file is symlink between frontend and backend. DO NOT move this file.
 * Frontend file is the source file
 */

export interface ProUser {
    userId: string
    email: string
}

export interface InstancesInfo {
    instances: CapRoverInstance[]
}

export enum ProAlertActionType {
    email = 'email',
    webhook = 'webhook',
}

export interface ProAlertAction {
    actionType: ProAlertActionType
    metadata?: any
}

export enum ProAlertEvent {
    UserLoggedIn = 'UserLoggedIn',
    AppBuildSuccessful = 'AppBuildSuccessful',
    AppBuildFailed = 'AppBuildFailed',
}

export interface ProAlertConfig {
    /*
    healthCheckEmailEnabled: boolean
    loginEmailEnabled: boolean
    buildSuccessEmailEnabled: boolean
    buildFailureEmailEnabled: boolean
    */
    event: ProAlertEvent
    action: ProAlertAction
}

export interface IProConfig {
    alerts: ProAlertConfig[]
}

export interface CapRoverInstance {
    instanceUuid: string
    apiKey: string
    url: string
    otpSecret: string
    userId: string
    email: string // used for alerts etc
    proConfigs: IProConfig | undefined
}

export enum SubscriptionPeriod {
    annual = 'annual',
    monthly = 'monthly',
}

export interface SubscriptionPlanDetails {
    planId: string
    period: SubscriptionPeriod
    price: number
}

export interface SubscriptionStatus {
    isActive: boolean
    subscriptionPlanId: string | undefined
    planDescription: string | undefined | undefined
    updateUrl: string | undefined
    cancelUrl: string | undefined
    validUntil: string | undefined
    quantity: string | undefined
    availablePlans: SubscriptionPlanDetails[]
}
