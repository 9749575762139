import { Button, Card, Col, Row } from 'antd'
import { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { SubscriptionStatus } from '../../models/ApiModels'

export default class UpgradedUser extends Component<
    {
        refreshSubscriptionStatus: () => void
        subscriptionStatus: SubscriptionStatus
    },
    {}
> {
    render() {
        const self = this
        const planDescription = self.props.subscriptionStatus.planDescription
        return (
            <div>
                <Row justify="center" gutter={20}>
                    <Col
                        style={{ margin: 20 }}
                        lg={{ span: 10 }}
                        xs={{ span: 23 }}
                    >
                        <Card
                            style={{ height: '100%' }}
                            title="CapRover Pro Account"
                        >
                            <p>
                                Your Pro subscription is currently active. You
                                have{' '}
                                <b>{self.props.subscriptionStatus.quantity}</b>{' '}
                                active license(s).
                            </p>
                            <p>
                                <ReactMarkdown remarkPlugins={[gfm]}>
                                    {planDescription || ''}
                                </ReactMarkdown>
                            </p>
                            <hr style={{ marginTop: 40 }} />

                            {self.renderValidUntil()}
                            {self.renderButtons()}
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

    renderValidUntil() {
        const self = this
        const validUntil = self.props.subscriptionStatus.validUntil
        if (!validUntil) {
            return <div></div>
        }

        return <div>Valid Until: {validUntil}</div>
    }

    renderButtons() {
        const self = this

        if (!self.props.subscriptionStatus.cancelUrl) {
            return undefined
        }

        return (
            <Row justify="end" gutter={20}>
                <Button
                    style={{ marginRight: 20 }}
                    danger
                    target="_blank"
                    href={self.props.subscriptionStatus.cancelUrl}
                >
                    Cancel subscription
                </Button>
                <Button
                    target="_blank"
                    href={self.props.subscriptionStatus.updateUrl}
                >
                    Update payment
                </Button>
            </Row>
        )
    }
}
