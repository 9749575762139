import {
    BarsOutlined,
    ClusterOutlined,
    LaptopOutlined,
    SettingOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Col, Layout, Menu, Row } from 'antd'
import React, { Fragment, RefObject } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { useAuth } from '../firebase/AuthContext'
import * as GlobalActions from '../redux/actions/GlobalActions'
import StorageHelper from '../utils/StorageHelper'
import LoggedInCatchAll from './LoggedInCatchAll'
import LogoutButton from './LogoutButton'
import AppDetails from './apps/appDetails/AppDetails'
import ApiComponent from './global/ApiComponent'
import DarkModeSwitch from './global/DarkModeSwitch'
import Dashboard from './instances/Dashboard'
import Settings from './settings/Settings'
import Subscription from './subscription/Subscription'

const { Header, Content, Sider } = Layout

const MENU_ITEMS: MenuProps['items'] = [
    {
        key: 'servers',
        label: 'Servers',
        icon: <LaptopOutlined />,
    },
    {
        key: 'subscription',
        label: 'Subscription',
        icon: <ClusterOutlined />,
    },
    {
        key: 'settings',
        label: 'Settings',
        icon: <SettingOutlined />,
    },
]

interface RootPageInterface extends RouteComponentProps<any> {
    rootElementKey: string
    emitSizeChanged: () => void
    isMobile: boolean
}

function VerifyLoggedIn() {
    const { currentUser } = useAuth()
    if (!currentUser) return <Redirect to="/login" />
    return <Fragment />
}

function UserInfoPrinter() {
    const { isAuthInited, currentUser } = useAuth()

    if (!process.env.REACT_APP_IS_DEBUG || !isAuthInited) return <div></div>

    return (
        <div>
            <pre
                style={{
                    margin: 0,
                    padding: 0,
                    maxHeight: 100,
                    overflowY: 'scroll',
                }}
            >
                {JSON.stringify(currentUser, undefined, 2)}
            </pre>
        </div>
    )
}

class PageRoot extends ApiComponent<
    RootPageInterface,
    {
        versionInfo: undefined
        collapsed: boolean
    }
> {
    private mainContainer: RefObject<HTMLDivElement>

    constructor(props: any) {
        super(props)
        this.mainContainer = React.createRef()
        this.state = {
            versionInfo: undefined,
            collapsed: false,
        }
    }

    updateDimensions = () => this.props.emitSizeChanged()

    componentWillUnmount() {
        // @ts-ignore
        if (super.componentWillUnmount) super.componentWillUnmount()
        this.updateDimensions()
        window.removeEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (
            this.props.location.pathname !== prevProps.location.pathname &&
            this.props.isMobile
        ) {
            this.setState({ collapsed: true })
        }
    }

    componentDidMount() {
        this.updateDimensions()

        window.addEventListener('resize', this.updateDimensions)

        this.setState({
            collapsed: StorageHelper.getSiderCollapsedStateFromLocalStorage(),
        })
    }

    toggleSider = () => {
        StorageHelper.setSiderCollapsedStateInLocalStorage(
            !this.state.collapsed
        )
        this.setState({ collapsed: !this.state.collapsed })
    }

    render() {
        const self = this
        return (
            <Layout className="full-screen">
                <VerifyLoggedIn />
                <UserInfoPrinter />
                <Header
                    className="header"
                    style={{
                        padding: `0 ${this.props.isMobile ? 15 : 50}px`,
                    }}
                >
                    <div>
                        <Row>
                            {this.props.isMobile && (
                                <Col span={4}>
                                    <Button
                                        ghost
                                        icon={<BarsOutlined />}
                                        onClick={this.toggleSider}
                                    />
                                </Col>
                            )}
                            {!this.props.isMobile && (
                                <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                    <div>
                                        <h3 style={{ color: '#fff' }}>
                                            <img
                                                alt="logo"
                                                src="/icon-512x512.png"
                                                style={{
                                                    height: 45,
                                                    marginRight: 10,
                                                }}
                                            />
                                            CapRover<b> Pro</b>
                                        </h3>
                                    </div>
                                </Col>
                            )}
                            {!self.props.isMobile && (
                                <Col span={12}>
                                    <Row justify="end">
                                        <span
                                            style={{
                                                marginRight: 30,
                                            }}
                                        >
                                            <DarkModeSwitch />
                                        </span>
                                        <span>
                                            <span
                                                style={{
                                                    border: '1px solid #1b8ad3',
                                                    borderRadius: 5,
                                                    padding: 8,
                                                }}
                                            >
                                                <LogoutButton />
                                            </span>
                                        </span>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Header>

                <Layout>
                    <Sider
                        breakpoint="lg"
                        trigger={this.props.isMobile && undefined}
                        collapsible
                        collapsed={this.state.collapsed}
                        width={200}
                        collapsedWidth={self.props.isMobile ? 0 : 80}
                        style={{ zIndex: 2 }}
                        onCollapse={this.toggleSider}
                    >
                        <Menu
                            selectedKeys={[
                                this.props.location.pathname.substring(1),
                            ]}
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={['servers']}
                            style={{ height: '100%', borderRight: 0 }}
                            items={MENU_ITEMS}
                            onClick={(e) => {
                                this.props.history.push(`/${e.key}`)
                            }}
                        >
                            {this.props.isMobile && (
                                <Fragment>
                                    <div
                                        style={{
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.65)',
                                            height: 1,
                                            width: '80%',
                                            margin: '15px auto',
                                        }}
                                    />

                                    <div
                                        className="ant-menu-item"
                                        role="menuitem"
                                        style={{ paddingLeft: 24 }}
                                    >
                                        <LogoutButton />
                                    </div>
                                </Fragment>
                            )}
                        </Menu>
                    </Sider>
                    <Content>
                        <div
                            key={self.props.rootElementKey || 'initial'}
                            ref={self.mainContainer}
                            style={{
                                paddingTop: 12,
                                paddingBottom: 36,
                                height: '100%',
                                overflowY: 'scroll',
                                marginRight: self.state.collapsed
                                    ? 0
                                    : self.props.isMobile
                                    ? -200
                                    : 0,
                                transition: 'margin-right 0.3s ease',
                            }}
                            id="main-content-layout"
                        >
                            <Switch>
                                <Route path="/servers/" component={Dashboard} />
                                <Route
                                    path="/apps/details/:appName"
                                    render={(props) => (
                                        <AppDetails
                                            {...props}
                                            mainContainer={self.mainContainer}
                                        />
                                    )}
                                />
                                <Route path="/settings/" component={Settings} />
                                <Route
                                    path="/subscription/"
                                    component={Subscription}
                                />
                                <Route path="/" component={LoggedInCatchAll} />
                            </Switch>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        rootElementKey: state.globalReducer.rootElementKey,
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect(mapStateToProps, {
    emitSizeChanged: GlobalActions.emitSizeChanged,
})(PageRoot)
