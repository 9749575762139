function loadPaddle(
    d: Document,
    s: string,
    id: string,
    onLoadFunction: Function
) {
    let fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
        return
    }
    let jsTag: any = d.createElement(s)
    jsTag.id = id
    jsTag.onload = function () {
        // remote script has loaded
        onLoadFunction()
    }
    jsTag.src = 'https://cdn.paddle.com/paddle/paddle.js'
    fjs.parentNode?.insertBefore(jsTag, fjs)
}

loadPaddle(document, 'script', 'paddle-js-id', () => {
    const wIgnore = window as any
    if (
        !!process.env.REACT_APP_PADDLE_USE_SANDBOX &&
        process.env.REACT_APP_PADDLE_USE_SANDBOX?.toLowerCase() === 'true'
    ) {
        wIgnore.Paddle.Environment.set('sandbox')
    }
    wIgnore.Paddle.Setup({
        vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || ''),
    })
})

class PaymentManager {
    checkout(
        productId: string,
        userEmail: string,
        userId: string,
        successCallback: Function,
        closeCallback: Function
    ) {
        const wIgnore = window as any
        wIgnore.Paddle.Checkout.open({
            product: parseInt(productId),
            email: userEmail,
            passthrough: JSON.stringify({ userId: userId }),
            disableLogout: true,
            successCallback: () => {
                successCallback()
            },
            closeCallback: () => {
                closeCallback()
            },
        })
    }
}

const paymentManager = new PaymentManager()
export default paymentManager
