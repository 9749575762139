import { CheckCircleTwoTone } from '@ant-design/icons'
import { Button, Card, Col, Modal, Row, Switch } from 'antd'
import React, { Component } from 'react'
import { FirebaseAuthManager } from '../../firebase/firebase'
import { SubscriptionPeriod, SubscriptionStatus } from '../../models/ApiModels'
import paymentManager from '../../payment/PaymentManager'
import Utils from '../../utils/Utils'
export default class StandardUser extends Component<
    {
        subscriptionStatus: SubscriptionStatus
        refreshSubscriptionStatus: () => void
    },
    {
        currentlySelectedPeriod: SubscriptionPeriod
        isPurchaseFlowLoading: boolean
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            currentlySelectedPeriod: SubscriptionPeriod.annual,
            isPurchaseFlowLoading: false,
        }
    }

    render() {
        const self = this
        return (
            <div style={{ margin: 30 }}>
                <Row justify="center">
                    <div style={{ margin: 5 }}>
                        <h3>
                            {self.state.currentlySelectedPeriod ===
                            SubscriptionPeriod.annual
                                ? 'Annual Saver Plan'
                                : 'Monthly Plan'}
                        </h3>
                    </div>
                </Row>
                <Row justify="center">
                    <Switch
                        size="default"
                        checkedChildren="Saving on"
                        unCheckedChildren="Saving off"
                        defaultChecked
                        onChange={(checked) => {
                            if (checked) {
                                self.setState({
                                    currentlySelectedPeriod:
                                        SubscriptionPeriod.annual,
                                })
                            } else {
                                self.setState({
                                    currentlySelectedPeriod:
                                        SubscriptionPeriod.monthly,
                                })
                            }
                        }}
                    />
                </Row>
                <Row justify="center" style={{ marginTop: 30 }}>
                    <Col style={{}} span={16}>
                        <Row
                            style={
                                {
                                    // backgroundColor: 'blue'
                                }
                            }
                            justify="space-around"
                        >
                            <Col style={{ padding: 10 }} span={12}>
                                {self.createFreeCard()}
                            </Col>
                            <Col style={{ padding: 10 }} span={12}>
                                {self.createPaidCard()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
    createPaidCard(): React.ReactNode {
        const self = this
        const plan = this.getPlan(this.state.currentlySelectedPeriod)

        const paidPlanCostNumber =
            plan.period === SubscriptionPeriod.monthly
                ? plan.price
                : plan.price / 12.0
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })

        const paidPlanCost = formatter.format(paidPlanCostNumber)

        return (
            <Card
                className="pricing-card"
                style={{
                    margin: 5,
                }}
                title={
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <h2
                            style={{
                                color: `#104f78`,
                                textAlign: 'center',
                            }}
                        >
                            <b>Pro Plan</b>
                        </h2>
                        {paidPlanCost} per month
                    </div>
                }
            >
                <p>
                    Everything that Free has,<b> plus:</b>
                </p>
                <div style={{ margin: 20 }}>
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        &nbsp;&nbsp;&nbsp;&nbsp;Two-factor authentication:
                    </div>
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        &nbsp;&nbsp;&nbsp;&nbsp;Proactive login monitoring
                    </div>
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        &nbsp;&nbsp;&nbsp;&nbsp;Build failure and success
                        monitoring
                    </div>
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        &nbsp;&nbsp;&nbsp;&nbsp;Up to <b>5</b> instances per
                        license
                    </div>
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        &nbsp;&nbsp;&nbsp;&nbsp;Email support (24hr SLA)
                    </div>
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        &nbsp;&nbsp;&nbsp;&nbsp;More to come!
                    </div>
                </div>

                <small>
                    <p>
                        <i>
                            Pricing is subject to change in the future. Active
                            subscriptions will benefit from fixed pricing as
                            long as the plan is available. Sign-up now to
                            lock-in your license cost. For more details
                            regarding the service and the refund policy, please
                            view{' '}
                            <a href="/terms-of-service.html" target="_blank">
                                Terms of Service
                            </a>
                            .
                        </i>
                    </p>
                </small>

                <div
                    style={{
                        margin: 30,
                        textAlign: 'center',
                    }}
                >
                    <Button
                        size="large"
                        type="primary"
                        loading={self.state.isPurchaseFlowLoading}
                        onClick={() => {
                            self.launchPurchase()
                        }}
                    >
                        <b>Upgrade NOW</b>
                    </Button>
                </div>
            </Card>
        )
    }
    createFreeCard(): React.ReactNode {
        return (
            <Card
                className="pricing-card"
                style={{
                    margin: 5,
                }}
                title={
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <h2
                            style={{
                                color: `#104f78`,
                                textAlign: 'center',
                            }}
                        >
                            <b>Free</b> <small>OSS</small>
                        </h2>
                        &nbsp;
                    </div>
                }
            >
                <ul>
                    <li>Free OSS (Open Source Software)</li>
                    <li>Deploy as many apps as you want</li>
                    <li>
                        Use hundreds of built-in apps, such as MongoDB, MySQL,
                        RocketChat, Grafana, etc.
                    </li>
                    <li>Free SSL Certificates issued by Let's Encrypt</li>
                    <li>Active community and great support</li>
                    <li>One-liner installation</li>
                    <li>Community support on Slack</li>
                </ul>
            </Card>
        )
    }

    launchPurchase() {
        const self = this
        return Promise.resolve() //
            .then(function () {
                self.setState({ isPurchaseFlowLoading: true })
                return new FirebaseAuthManager().getUser()
            })
            .then(function (user) {
                const plan = self.getPlan(self.state.currentlySelectedPeriod)
                return paymentManager.checkout(
                    plan.planId,
                    user.email || '',
                    user.uid,
                    () => {
                        self.displayUpgradeSucceeded()
                    },
                    () => {
                        // User manually closed payment
                        // Do nothing!
                    }
                )
            })
            .then(function () {
                return Utils.getDelayedPromise(4500)
            })
            .then(function () {
                self.setState({ isPurchaseFlowLoading: false })
            })
    }
    displayUpgradeSucceeded() {
        const self = this
        Modal.success({
            title: 'Congratulations!',
            content: (
                <div>
                    <hr />
                    <br />
                    <p>Subscription plan purchase succeeded.</p>
                    <p>
                        You should now have access to premium features of
                        CapRover such as two-factor authentication.
                    </p>
                    <p> Enjoy!</p>
                    <p>
                        <small>
                            <i>
                                NOTE: During peak times, it may take a few
                                minutes before your subscription gets
                                registered. Log out and log in again after a few
                                minutes if your upgraded status does not show up
                                immediately.
                            </i>
                        </small>
                    </p>
                </div>
            ),
            onCancel: () => {
                self.props.refreshSubscriptionStatus()
            },
            onOk: () => {
                self.props.refreshSubscriptionStatus()
            },
        })
    }

    getPlan(period: SubscriptionPeriod) {
        const value = this.props.subscriptionStatus.availablePlans.find(
            (it) => it.period === period
        )
        if (!value) throw new Error('Plan not found for ' + period)

        return value
    }
}
