import { LogoutOutlined } from '@ant-design/icons'
import { useAuth } from '../firebase/AuthContext'
import Logger from '../utils/Logger'
import Toaster from '../utils/Toaster'
import ClickableLink from './global/ClickableLink'
export default function LogoutButton() {
    const { logout } = useAuth()
    return (
        <ClickableLink
            onLinkClicked={() => {
                logout()
                    .then(function () {
                        // Sign-out successful.
                        window.location.reload()
                    })
                    .catch(function (error: any) {
                        Logger.error(error)
                        Toaster.toast('Something went wrong')
                    })
            }}
        >
            Logout <LogoutOutlined />
        </ClickableLink>
    )
}
