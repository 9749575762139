import React from 'react'
import { RouteComponentProps } from 'react-router'
import { SubscriptionStatus } from '../../models/ApiModels'
import Toaster from '../../utils/Toaster'
import Utils from '../../utils/Utils'
import ApiComponent from '../global/ApiComponent'
import CenteredSpinner from '../global/CenteredSpinner'
import ErrorRetry from '../global/ErrorRetry'
import StandardUser from './StandardUser'
import UpgradedUser from './UpgradedUser'

export default class Subscription extends ApiComponent<
    RouteComponentProps<any>,
    {
        isLoading: boolean
        apiData: { subscriptionStatus: SubscriptionStatus } | undefined
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            apiData: undefined,
        }
    }

    componentDidMount() {
        if (super.componentDidMount) super.componentDidMount()
        const self = this
        self.refreshStatus()
    }

    refreshStatus() {
        const self = this
        self.setState({ isLoading: true, apiData: undefined })

        this.apiManager
            .getSubscriptionStatus() //
            .then(function (subscriptionStatus) {
                let oldApiData = self.state.apiData
                    ? Utils.copyObject(self.state.apiData)
                    : undefined
                if (!oldApiData) {
                    oldApiData = { subscriptionStatus: subscriptionStatus }
                } else {
                    oldApiData.subscriptionStatus = subscriptionStatus
                }
                self.setState({ apiData: oldApiData })
            })
            .catch(Toaster.createCatcher())
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    render() {
        const self = this

        if (self.state.isLoading) {
            return (
                <div>
                    <CenteredSpinner />
                </div>
            )
        }

        if (!self.state.apiData) {
            return <ErrorRetry />
        }

        if (self.state.apiData.subscriptionStatus.isActive) {
            return (
                <UpgradedUser
                    refreshSubscriptionStatus={() => {
                        self.refreshStatus()
                    }}
                    subscriptionStatus={self.state.apiData.subscriptionStatus}
                />
            )
        } else {
            return (
                <StandardUser
                    refreshSubscriptionStatus={() => {
                        self.refreshStatus()
                    }}
                    subscriptionStatus={self.state.apiData.subscriptionStatus}
                />
            )
        }
    }
}
