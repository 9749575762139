import { Redirect } from 'react-router'
import { useAuth } from '../firebase/AuthContext'

export default function LoggedInCatchAll() {
    const { currentUser, isAuthInited } = useAuth()

    if (!isAuthInited) return <div />
    if (!currentUser) return <Redirect to="/login" />

    return <Redirect to={`/servers`} />
}
