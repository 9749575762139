import { Card, Col, Row } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FirebaseAuthManager } from '../../firebase/firebase'
import { IMobileComponent } from '../../models/ContainerProps'

class Settings extends Component<
    {
        isMobile: boolean
    },
    {
        userDetails: firebase.default.User | undefined
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            userDetails: undefined,
        }
    }

    componentDidMount() {
        const self = this
        new FirebaseAuthManager()
            .getUser() //
            .then(function (user) {
                self.setState({ userDetails: user })
            })
    }

    render() {
        const self = this

        const user = self.state.userDetails

        if (!user) {
            return <div></div>
        }

        return (
            <div>
                <Row justify="center" gutter={20}>
                    <Col
                        style={{ margin: 20 }}
                        lg={{ span: 10 }}
                        xs={{ span: 23 }}
                    >
                        <Card style={{ height: '100%' }} title="Profile">
                            <p>
                                Name: <b>{user.displayName}</b>
                            </p>
                            <p>
                                Email: <b>{user.email}</b>
                            </p>
                            <p>
                                Authentication:{' '}
                                <b>{user.providerData[0]?.providerId}</b>
                            </p>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        isMobile: state.globalReducer.isMobile,
    }
}

export default connect<IMobileComponent, any, any>(
    mapStateToProps,
    undefined
)(Settings)
