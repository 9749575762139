import { Redirect } from 'react-router'
import { useAuth } from '../../firebase/AuthContext'
import LandingPage from './LandingPage'

export default function LoginOrSignUp(props: {
    emitSizeChanged: () => void
    isMobile: boolean
}) {
    const { isAuthInited, currentUser } = useAuth()

    if (!isAuthInited) return <div />

    if (currentUser) return <Redirect to="/" />

    return (
        <div>
            <LandingPage
                emitSizeChanged={props.emitSizeChanged}
                isMobile={props.isMobile}
            />
        </div>
    )
}
